import React from "react";
import { Link } from "react-router-dom";
import ArrowBackIcon from "../atoms/Icons/ArrowBackIcon";
import ProfileIcon from "components/atoms/Icons/ProfileIcon";

export type HeaderProps = {
  variant: "profile" | "home" | "claim" | "status";
  onEditProfile?: () => void;
  onBack?: () => void;
  editing?: boolean;
  reporter?: boolean;
};

export const Header: React.FC<HeaderProps> = ({
  variant,
  onEditProfile,
  onBack = () => {},
  editing = false,
  reporter = false,
}) => {
  return (
    <div className="header">
      <div className={"back" + (variant === "claim" ? " right" : "")}>
        <h1>
          {variant === "home" ? (
            `B2B Claims Portal ${reporter ? " - Reporter" : ""}`
          ) : (
            <div onClick={onBack}>
              {variant === "profile" || variant === "status" ? <ArrowBackIcon /> : <>X</>}
            </div>
          )}
        </h1>
      </div>
      <div>
        {variant === "profile" && (
          <div className="hover" onClick={onEditProfile}>
            {editing ? "Back to" : "Edit"} Profile
          </div>
        )}
        {variant === "home" && (
          <Link to="/profile">
            <ProfileIcon height="33" width="33" />
          </Link>
        )}
      </div>
    </div>
  );
}; 
