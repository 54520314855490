import { UppyFile } from "@uppy/core";
import Checkbox from "components/atoms/Checkbox";
import Dropdown from "components/atoms/Dropdown";
import ImageUploader from "components/atoms/ImageUploader";
import InfoTooltip from "components/atoms/InfoTooltip";
import TextInput from "components/atoms/TextInput";
import ItemManager from "components/organisms/ItemManager";
import ValidatedInput from "components/organisms/ValidatedInput";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import {
  fetchItems as fetch,
  postItem as post,
  patchItem as patch,
  getProductInfo,
  deleteItem,
} from "services/ItemService";
import { deletePhoto, savePhoto } from "services/PhotoService";
import { Item } from "types";
import { ClaimType } from "types/claim";
import { ClaimComponentProps } from ".";

export type ClaimItemsProps = {
  type: ClaimType;
  claimId: string;
  edit: boolean;
  setEdit: React.Dispatch<boolean>;
  setItemDone: React.Dispatch<any>;
  setHandleDone: React.Dispatch<any>;
  setHandleBack: React.Dispatch<any>;
  setStay: React.Dispatch<boolean>;
} & ClaimComponentProps;

const ClaimItems: React.FC<ClaimItemsProps> = ({
  type,
  claimId,
  edit,
  setEdit,
  setItemDone,
  setHandleBack,
  setHandleDone,
  setCanProgress,
  setOnNext,
  setStay,
  viewOnly,
}) => {
  const [itemList, setItemList] = useState([]);
  const [missing, setMissing] = useState([]);
  const [currentItem, setCurrentItem] = useState(0);
  const [newItems, setNewItems] = useState([]);
  const [toUpload, setToUpload] = useState([]);
  useEffect(() => {
    setCanProgress(false);
    setStay(true);
    setOnNext(() => async () => {});
    getItems();
  }, []);

  useEffect(() => {
    if (missing.length === 0 && newItems.length === 0 && itemList.length > 0 && !viewOnly) {
      setOnNext(() => async () => {
        setStay(false);
      });
      setCanProgress(true);
    } else {
      setCanProgress(false);
    }
  }, [itemList, newItems, missing, viewOnly]);

  const getItems = async () => {
    fetch(parseInt(claimId), type).then((res: Item[]) => {
      let itemResult: Item[] = res;
      setItemList(itemResult);
      setToUpload([...Array(itemResult.length)].map((e) => Array(0)));
    });
  };

  const handleAdd = async () => {
    setNewItems([...newItems, itemList.length]);
    setToUpload(toUpload.concat([[]]));
    setItemList(itemList.concat([{}]));
    setCurrentItem(itemList.length);
    setEdit(true);
  };

  const handleDelete = (item: Item, i: number) => {
    let currentList = itemList;
    let currentPhotos = toUpload;
    currentList.splice(i, 1);
    currentPhotos.splice(i, 1);
    setItemList(currentList);
    setToUpload(currentPhotos);
    setMissing(
      missing
        .filter((m) => m !== i)
        .map((m) => {
          if (m > i) {
            return m - 1;
          } else if (m < i) {
            return m;
          } else {
            return 0;
          }
        })
    );
    setNewItems(
      newItems
        .filter((m) => m !== i)
        .map((m) => {
          if (m > i) {
            return m - 1;
          } else if (m < i) {
            return m;
          }
        })
    );
    if (currentItem > i) {
      setCurrentItem(currentItem - 1);
    }
    if (item.id) {
      deleteItem(item.id, parseInt(claimId), type);
    }
  };

  const modifyUpload = (newPhotos: any[], i: number) => {
    let currentUpload = JSON.parse(JSON.stringify(toUpload));
    currentUpload[i] = newPhotos;
    setToUpload(currentUpload);
  };

  const handleEdit = (item: Item, i: number) => {
    setCurrentItem(i);
    setEdit(true);
  };

  const replaceItem = (item: Item, i: number) => {
    if (viewOnly) return;
    let currentList = itemList;
    currentList[i] = item;
    setItemList(currentList);
  };

  return (
    <div>
      <div>
        <strong>Item Details</strong>

        {edit ? (
          <ItemForm
            item={itemList[currentItem]}
            index={currentItem}
            toPost={newItems.includes(currentItem)}
            setHandleBack={setHandleBack}
            setHandleDone={setHandleDone}
            setItemDone={setItemDone}
            setMissing={setMissing}
            missing={missing}
            claimId={claimId}
            type={type}
            newItems={newItems}
            setNewItems={setNewItems}
            setEdit={setEdit}
            currentItem={currentItem}
            replaceItem={(item: Item) => replaceItem(item, currentItem)}
            toUpload={toUpload[currentItem]}
            clearToUpload={() => {
              modifyUpload([], currentItem);
            }}
            modifyUpload={(newPhotos: any[]) => {
              modifyUpload(newPhotos, currentItem);
            }}
            viewOnly={viewOnly}
          />
        ) : (
          <ItemManager
            missing={missing}
            items={itemList}
            onAdd={handleAdd}
            onDelete={handleDelete}
            onEdit={handleEdit}
            viewOnly={viewOnly}
          />
        )}
      </div>
    </div>
  );
};

export type ItemFormProps = {
  item: Item;
  index: number;
  toPost: boolean;
  setHandleBack: React.Dispatch<any>;
  setHandleDone: React.Dispatch<any>;
  setItemDone: React.Dispatch<any>;
  setMissing: React.Dispatch<any>;
  missing: number[];
  claimId: string;
  type: ClaimType;
  newItems: number[];
  setNewItems: React.Dispatch<any>;
  setEdit: React.Dispatch<any>;
  currentItem: number;
  replaceItem: (item: Item) => void;
  toUpload: any[];
  clearToUpload: () => void;
  modifyUpload: (newPhotos: any[]) => void;
  viewOnly: boolean;
};

const ItemForm: React.FC<ItemFormProps> = ({
  item,
  index,
  toPost,
  setHandleBack,
  setHandleDone,
  setItemDone,
  setMissing,
  missing,
  claimId,
  type,
  newItems,
  setNewItems,
  setEdit,
  currentItem,
  replaceItem,
  toUpload,
  clearToUpload,
  modifyUpload,
  viewOnly,
}) => {
  const [newItem, setNewItem] = useState(
    item ? { ...JSON.parse(JSON.stringify(item)), receivalDate: item.receivalDate } : {}
  );
  const [customItem, setCustomItem] = useState(false);
  const [photos, setPhotos] = useState(
    newItem && newItem.photos
      ? newItem.photos.map((photo) => {
          return photo.mimetype === "application/pdf"
            ? `pdf-${photo.name} |<break>| ${photo.url}`
            : photo.url;
        })
      : []
  );
  const [toDelete, setToDelete] = useState([]);
  const [dateError, setDateError] = useState(false);

  const REQUIRED_FIELDS =
    type === ClaimType.Refund
      ? ["itemNumber", "productDescription", "reason"]
      : [
          "itemNumber",
          "productDescription",
          "receivalDate",
          "claimCategory",
        ];
  const BREAKAGE_FIELDS = [
    "caseSealed",
    "breakageType",
    "breakageLocation",
  ];

  useEffect(() => {
    setHandleBack(() => async () => {
      replaceItem(newItem);
      setEdit(false);
    });
    let changes = [];
    if (item.itemNumber > 0) {
      changes.push({ field: "itemNumberTemp", value: item.itemNumber });
    }
    if (item.receivalDate) {
      changes.push({
        field: "receivalDate",
        value:
          typeof item.receivalDate === "string"
            ? DateTime.fromISO(item.receivalDate).toISODate()
            : item.receivalDate.toISODate(),
      });
    }
    modifyItem(changes);
    const isDone = isItemDone();
    setItemDone(isDone);
    setMissing(isDone ? missing.filter((num) => num !== index) : [...new Set([...missing, index])]);
    setHandleDone(() => async () => {
      toPost ? postItem() : patchItem();
      setEdit(false);
    });
  }, []);

  useEffect(() => {
    setHandleBack(() => async () => {
      replaceItem(newItem);
      setEdit(false);
    });
    const isDone = isItemDone();
    setItemDone(isDone);
    setMissing(
      viewOnly
        ? []
        : isDone
        ? missing.filter((num) => num !== index)
        : [...new Set([...missing, index])]
    );
    setHandleDone(() => async () => {
      toPost ? postItem() : patchItem();
      setEdit(false);
    });
  }, [newItem]);

  const isItemDone = () => {
    let requiredFields = REQUIRED_FIELDS;
    let valid = true;
    if (type === ClaimType.Refund) {
      if (!(newItem.reason?.length > 0)) {
        return false;
      } else if (photos.length === 0) {
        return false;
      }
    } else {
      if (!(DateTime.fromISO(newItem.receivalDate) < DateTime.now())) {
        return false;
      }
      if (photos.length === 0) {
        return false;
      } else if (newItem.claimCategory === "damage") {
        requiredFields = requiredFields.concat(BREAKAGE_FIELDS);
      }
    }
    requiredFields.forEach((field) => {
      if (!newItem.hasOwnProperty(field)) {
        valid = false;
      }
    });
    if (!valid || (!newItem.cases && !newItem.units && !newItem.unitsClaimed)) {
      return false;
    }

    if (!(newItem.productDescription?.length > 0)) {
      return false;
    }
    return true;
  };

  const patchItem = () => {
    let itemInfo = JSON.parse(JSON.stringify(newItem));
    delete itemInfo.id;
    delete itemInfo.claimId;
    patch(parseInt(newItem.id), parseInt(claimId), type, {
      ...newItem,
      receivalDate: DateTime.fromISO(newItem.receivalDate).toISO(),
      units: !(newItem.units >= 0) ? 0 : newItem.units,
      unitsClaimed: !(newItem.unitsClaimed >= 0) ? 0 : newItem.unitsClaimed,
      cases: !(newItem.cases >= 0) ? 0 : newItem.cases,
    }).then(async (res) => {
      let existingPhotos = newItem.photos?.length > 0 ? newItem.photos : [];
      let photoRes =
        toUpload?.length > 0
          ? await Promise.all(
              toUpload?.map(async (photo) => {
                return await savePhoto(type, parseInt(claimId), photo, newItem.id);
              })
            )
          : [];

      replaceItem({
        ...newItem,
        photos: [...existingPhotos, ...photoRes.map((p) => p.data)].filter(
          (p) => !toDelete.includes(p.id)
        ),
      });

      await Promise.all(toDelete.map( async (photo) => {
        return await deletePhoto(type, parseInt(claimId), photo, newItem.id)
      }))

      clearToUpload();
      setEdit(false);
    });
  };

  const postItem = () => {
    post(parseInt(claimId), type, {
      ...newItem,
      receivalDate: DateTime.fromISO(newItem.receivalDate).toISO(),
      units: !(newItem.units >= 0) ? 0 : newItem.units,
      unitsClaimed: !(newItem.unitsClaimed >= 0) ? 0 : newItem.unitsClaimed,
      cases: !(newItem.cases >= 0) ? 0 : newItem.cases,
    }).then((res) => {
      Promise.all(
        toUpload?.map(async (photo) => {
          return await savePhoto(type, parseInt(claimId), photo, res.id);
        })
      ).then((photoRes) => {
        setEdit(false);
        setNewItems(newItems.filter((itm) => itm !== currentItem));
        replaceItem({ ...newItem, id: res.id, photos: [...photoRes.map((res) => res.data)] });
        clearToUpload();
      });
    });
  };

  const modifyItem = async (changes: { field: string; value: any }[]) => {
    let newInfo = JSON.parse(JSON.stringify(newItem));
    changes.forEach((change) => (newInfo[change.field] = change.value));
    setNewItem(newInfo);
    return newInfo;
  };

  const getProduct = (itemNumber: number) => {
    getProductInfo([itemNumber]).then((info) => {
      if (info?.length > 0) {
        modifyItem([
          { field: "productDescription", value: `${info[0]?.itemName} (${info[0].productSize})` },
          { field: "itemNumber", value: parseInt(info[0]?.itemNumber) },
        ]);
      } else {
        modifyItem([
          { field: "productDescription", value: `` },
          { field: "itemNumber", value: itemNumber },
        ]);
      }
    });
  };

  const clearAdjustmentItem = (info) => {
    let newInfo = JSON.parse(JSON.stringify(info));
    BREAKAGE_FIELDS.forEach((field) => {
      delete newInfo[field];
    });
    setNewItem(newInfo);
  };

  const handleUpload = (newPhotos: UppyFile[]) => {
    const newPhotoURLs = newPhotos.map((photo) => {
      if (photo.type) {
        return photo.type === "application/pdf"
          ? `pdf-${photo.name} |<break>| ${URL.createObjectURL(photo.data)}`
          : photo.preview;
      }
      return undefined;
    });
    setPhotos([...photos, ...newPhotoURLs]);
    modifyUpload([...toUpload, ...newPhotos]);
    modifyItem([{ field: "dummy", value: "" }]);
  };

  const handleDelete = (deleted) => {
    setPhotos(photos.filter((photo) => photo !== deleted));
    const newUploadList = toUpload.filter((photo) => {
      if (deleted.substring(0, 3) === "pdf") {
        return !deleted.includes(`${photo.name}`);
      } else {
        return photo.preview !== deleted;
      }
    });
    modifyUpload(newUploadList);
    if (newItem.photos) {
      let deletedPhoto = newItem.photos.filter((p) => deleted.includes(p.url));

      if (deletedPhoto.length > 0) {
        setToDelete([...toDelete, ...deletedPhoto.map((p) => p.id)]);
      }
    }
    modifyItem([{ field: "dummy", value: "" }]);
  };

  const handleDate = (input) => {
    const date = DateTime.fromISO(input);
    setDateError(date > DateTime.now());
    return date.toISODate();
  };

  return (
    <div className="item-form">
      <br />
      <h4>Basic Information</h4>

      <div className="info-fields">
        <div className="info-field">
          <div className="form-row">
            <ValidatedInput
              label="LCBO Item Number"
              onChange={(e) => {
                modifyItem([{ field: "itemNumberTemp", value: parseInt(e.target.value) }]);
              }}
              disabled={customItem || viewOnly}
              value={
                newItem.itemNumberTemp > 0 || newItem.itemNumberTemp?.length > 0
                  ? newItem.itemNumberTemp
                  : ""
              }
              type="number"
              sizing="md"
              triggerCheck={[newItem.itemNumber, newItem.productDescription]}
              hideOnPass
              validations={[
                {
                  label: `Item Number ${newItem.itemNumber} could not be found. Please try again.`,
                  condition: (value: string) => {
                    return (
                      customItem ||
                      !newItem.itemNumberTemp ||
                      !newItem.itemNumber ||
                      newItem.productDescription?.length > 0 ||
                      newItem.itemNumber < 0
                    );
                  },
                },
              ]}
            />
            &nbsp;
            {newItem.itemNumberTemp > 0 && !viewOnly && (
              <button
                className="search-container"
                onClick={() => getProduct(parseInt(newItem.itemNumberTemp))}
              >
                <div className="search">🔎</div>
              </button>
            )}
          </div>
        </div>
        <div className="form-row">
          <Checkbox
            onChange={() => {
              setCustomItem(!customItem);
              modifyItem([
                { field: "itemNumber", value: -1 },
                { field: "itemNumberTemp", value: -1 },
                { field: "productDescription", value: "" },
              ]);
            }}
            disabled={viewOnly}
          />
          &nbsp;&nbsp;
          <div>
            <p>I do not have the Item Number; item not ordered/listed.</p>
          </div>
        </div>
        <div className="info-field">
          <TextInput
            className="mobile-black"
            label="Item Description"
            onChange={(e) => {
              modifyItem([{ field: "productDescription", value: e.target.value }]);
            }}
            asterisk
            value={newItem.productDescription}
            disabled={!customItem || viewOnly}
          />
        </div>
        {customItem && !viewOnly && (
          <h6>
            <ul>
              <li>Please fill out the Item Description so LCBO can match your product</li>
            </ul>
          </h6>
        )}

        {type !== ClaimType.Refund && (
          <div className="info-field">
            <TextInput
              label="Receival Date"
              onChange={(e) => {
                modifyItem([{ field: "receivalDate", value: handleDate(e.target.value) }]);
              }}
              value={DateTime.fromISO(newItem.receivalDate).toISODate()}
              type="date"
              max={new Date().toISOString().split("T")[0]}
              disabled={viewOnly}
              asterisk
            />
            <p className="mt-1 incomplete input-warning">
              {dateError ? "Cannot select future date" : ""}
            </p>
          </div>
        )}
        {type === ClaimType.Adjustment && (
          <>
            <div className="info-field">
              <Dropdown
                label="Claim Category"
                placeholder=""
                options={[
                  { label: "Breakage", value: "damage" },
                  { label: "Overage", value: "over" },
                  { label: "Shortage", value: "short" },
                ]}
                onSelect={(value) => {
                  modifyItem([{ field: "claimCategory", value }]).then((res) => {
                    clearAdjustmentItem(res);
                  });
                }}
                defaultValue={newItem.claimCategory ? newItem.claimCategory : ""}
                disabled={viewOnly}
                asterisk
              />
            </div>
          </>
        )}
      </div>
      <br />
      <h4>Quantity</h4>
      <div className="info-fields">
        <div className="info-field">
          <TextInput
            label={
              <div className="form-row-tip">
                <div>Units Claimed</div>
                &nbsp;
                <div className="tip">
                  <InfoTooltip type="units" />
                </div>
              </div>
            }
            onChange={(e) => {
              modifyItem([
                {
                  field: `units${type === ClaimType.Refund ? "" : "Claimed"}`,
                  value: e.target.value?.length > 0 ? parseInt(e.target.value) : 0,
                },
              ]);
            }}
            value={
              newItem[`units${type === ClaimType.Refund ? "" : "Claimed"}`] > 0
                ? newItem[`units${type === ClaimType.Refund ? "" : "Claimed"}`]
                : ""
            }
            type="number"
            disabled={viewOnly}
          />
        </div>
      </div>
      <br />
      <div className="info-fields">
        {type === ClaimType.Refund && (
          <div className="info-field">
          <h4>Additional Information</h4>
            <TextInput
              label={"Reason for Return"}
              onChange={(e) => {
                modifyItem([{ field: "reason", value: e.target.value }]);
              }}
              value={newItem.reason}
              sizing="lg"
              rows={3}
              disabled={viewOnly}
              asterisk
            />
          </div>
        )}
        {newItem.claimCategory === "damage" && (
          <>
            <div className="info-field">
            <h4>Additional Information</h4>
              <RadioGroup
                label="Case Sealed"
                value={newItem.caseSealed}
                onClick={(value: any) =>
                  modifyItem([
                    { field: "caseSealed", value: newItem?.caseSealed === value ? !value : value },
                  ])
                }
                disabled={viewOnly}
                asterisk
              />
            </div>
            <div className="info-field">
              <RadioGroup
                label="Breakage Type"
                value={newItem.breakageType}
                onClick={(value: any) =>
                  modifyItem([
                    {
                      field: "breakageType",
                      value:
                        (newItem?.breakageType === value ? 1 : 0) ^ (value === "dry" ? 1 : 0)
                          ? "dry"
                          : "wet",
                    },
                  ])
                }
                options={[
                  { label: "Wet", value: "wet" },
                  { label: "Dry", value: "dry" },
                ]}
                disabled={viewOnly}
                asterisk
              />
            </div>
            <div className="info-field">
              <Dropdown
                label="Breakage Location"
                placeholder=""
                options={[
                  { label: "Truck", value: "truck" },
                  { label: "Store", value: "store" },
                  { label: "Supplying Source", value: "supplyingSource" },
                  { label: "None", value: "none" },
                ]}
                onSelect={(value) => {
                  modifyItem([{ field: "breakageLocation", value }]);
                }}
                value={newItem.breakageLocation ? newItem.breakageLocation : ""}
                disabled={viewOnly}
                asterisk
              />
            </div>
          </>
        )}
      </div>
      <div className="info-fields">
        <div className="info-field">
          <div className="image-copy">
            <div>
              <p>
                <strong>
                  {type === ClaimType.Refund ? "ITEM PHOTOS" : "UPLOAD PHOTOS AND DOCUMENTS"}
                </strong>
                  <div className="asterisk">*</div>
              </p>
            </div>

            <div>
              <p>
                <ul>
                  <li>
                    Product photos are mandatory
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
        <ImageUploader
          id={type + " " + claimId + " item " + currentItem.toString()}
          disableChanges={viewOnly}
          images={photos}
          onUpload={handleUpload}
          onDelete={handleDelete}
        />
      </div>
    </div>
  );
};

export type RadioGroupProps = {
  label: string;
  value: any;
  onClick: (value: any) => void;
  options?: { label: string; value: any }[];
  disabled?: boolean;
  asterisk?: boolean;
};

const RadioGroup: React.FC<RadioGroupProps> = ({
  label,
  value,
  onClick,
  options = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ],
  disabled = false,
  asterisk = false,
}) => (
  <div className="radio-group">
    <div className="label">
      {label}
      {asterisk && <div className="asterisk">*</div>}
    </div>
    <div className="radio">
      {options.map((pair) => (
        <>
          <input
            onClick={() => {
              if (!disabled) onClick(pair.value);
            }}
            checked={value === pair.value}
            type="radio"
            disabled={disabled}
          />
          <div className="word">{pair.label}</div>
        </>
      ))}
    </div>
  </div>
);

export default ClaimItems;
