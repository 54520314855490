import { Button } from "components";
import { useState } from "react";
import { Modal } from "react-bootstrap";

export type RadioGroupModalProps = {
  onSubmit: (selections: Object) => void;
  onCancel: () => void;
  groups: {
    key: string;
    clearable?: boolean;
    required?: boolean;
    multiselect?: boolean;
    label: string;
    options: { label: string; value: any }[];
  }[];
  title: string;
  show: boolean;
  allClearable?: boolean;
  defaultSelections?: Object;
};

const RadioGroupModal: React.FC<RadioGroupModalProps> = ({
  onSubmit,
  onCancel,
  groups,
  title,
  show,
  allClearable = false,
  defaultSelections,
}) => {
  const [selections, setSelections] = useState(
    defaultSelections
      ? defaultSelections
      : groups.reduce(
          (obj, group) => Object.assign(obj, { [group.key]: group.multiselect ? [] : null }),
          {}
        )
  );
  const toggleSelection = (key: string, value: any, multiselect: boolean = false) => {
    const current = JSON.parse(JSON.stringify(selections));
    if (current[key] === value) {
      current[key] = multiselect ? [] : null;
    } else if (Array.isArray(current[key]) && current[key].includes(value)) {
      current[key] = current[key].filter((el) => el !== value);
    } else {
      current[key] = multiselect ? current[key].concat([value]) : value;
    }
    setSelections(current);
  };
  return (
    <Modal show={show} onHide={onCancel}>
      <div className="radio-group-modal-content">
        <div>
          <h2>{title}</h2>
        </div>
        {groups.map((group) => (
          <div className="group">
            <div className="heading">
              <div>
                <h3>{group.label}</h3>
              </div>
              {group.clearable && (
                <div
                  onClick={() => {
                    toggleSelection(group.key, [], false);
                  }}
                  className="clear"
                >
                  Clear
                </div>
              )}
            </div>
            {group.options.map((option) => (
              <div className="option">
                <div className="radio">
                  <input
                    checked={
                      group.multiselect
                        ? selections[group.key]?.includes(option.value)
                        : selections[group.key] === option.value
                    }
                    onClick={() => {
                      toggleSelection(group.key, option.value, group.multiselect);
                    }}
                    type={group.multiselect ? "checkbox" : "radio"}
                  />
                </div>
                {option.label}
              </div>
            ))}
          </div>
        ))}
        <br />
        <div className="button-row">
          <div className="cancel-all">
            <Button
              className="clear-btn"
              disabled={!allClearable}
              onClick={() => {
                setSelections(
                  groups.reduce(
                    (obj, group) =>
                      Object.assign(obj, { [group.key]: group.multiselect ? [] : null }),
                    {}
                  )
                );
              }}
            >
              {allClearable ? "Clear All" : ""}
            </Button>
          </div>

          <div className="button-pair">
            <div>
              <button
                className="group-modal-btn"
                onClick={() => {
                  onCancel();
                }}
              >
                Cancel
              </button>
            </div>
            <div>
              <button
                className="group-modal-btn"
                disabled={
                  groups.filter((group) => group.required && selections[group.key] === null)
                    .length > 0
                }
                onClick={() => {
                  onSubmit(selections);
                }}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RadioGroupModal;
