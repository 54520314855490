import axios from "axios";
import { config } from '../config';

let baseURL = config.base_url;

const instance = axios.create({
  baseURL: baseURL,
});

export default instance;
