import { Header } from "components";
import ReporterClaimCard from "components/atoms/ReporterClaimCard";
import Search from "components/atoms/Search";
import ClaimInfoModal from "components/organisms/ClaimInfoModal";
import { Button } from "components/atoms/Button";
import { useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { Modal, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { DateTime } from "luxon";
import {
  exportFileName,
  exportPDF,
  fetchClaims,
  getChannels,
  getStores,
} from "services/ClaimService";
import { UserService } from "services/UserService";
import { Channel, Claim, ClaimStatus, ClaimType, Item, Reporter, Store, UserType } from "types";
import FilterIcon from "components/atoms/Icons/FilterIcon";
import HamburgerIcon from "components/atoms/Icons/HamburgerIcon";
import WindowIcon from "components/atoms/Icons/WindowIcon";
import RadioGroupModal from "components/organisms/RadioGroupModal";
import StickyFooter from "components/organisms/StickyFooter";
import { ExportToCsv } from "export-to-csv";
import { mapClaimStatus } from "types/claim";

const ReporterHome: React.FC = () => {
  const history = useHistory();
  const [user, setUser] = useState<Reporter>();
  const [claims, setClaims] = useState<Claim[]>([]);
  const [filters, setFilters] = useState({
    claimType: Object.values(ClaimType),
    status: [
      ClaimStatus.Completed,
      ClaimStatus.Validating,
      ClaimStatus.Submitted,
      ClaimStatus.Incomplete,
      ClaimStatus.Pending,
    ],
    channels: [],
  });
  const [channels, setChannels] = useState(new Map());
  const [stores, setStores] = useState(new Map());
  const [filteredClaims, setFilteredClaims] = useState<Claim[]>([]);
  const [searchClaims, setSearchClaims] = useState<Claim[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentClaim, setCurrentClaim] = useState<Claim>();
  const [showInfo, setShowInfo] = useState<boolean>(false);
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [showExports, setShowExports] = useState<boolean>(false);
  const [cardView, setCardView] = useState(true);

  const exportGroups = useMemo(
    () => [
      {
        key: "fileType",
        label: "FILE TYPE",
        required: true,
        options: [
          { label: "PDF document (.pdf)", value: "pdf" },
          { label: "CSV file (.csv)", value: "csv" },
        ],
      },
      {
        key: "filtered",
        label: "FILTERS",
        required: true,
        options: [
          { label: "With filters", value: true },
          { label: "Without filters", value: false },
        ],
      },
    ],
    []
  );

  let filterGroups = [
    {
      key: "claimType",
      label: "TYPE",
      clearable: true,
      multiselect: true,
      options: [
        { label: "Refund", value: ClaimType.Refund },
        { label: "Recall", value: ClaimType.Recall },
        { label: "Adjustment", value: ClaimType.Adjustment },
      ],
    },
    {
      key: "status",
      label: "STATUS",
      clearable: true,
      multiselect: true,
      options: [
        { label: "Completed", value: ClaimStatus.Completed },
        { label: "Grocer Submitted", value: ClaimStatus.Submitted },
        { label: "Incomplete", value: ClaimStatus.Incomplete },
        { label: "Pending", value: ClaimStatus.Pending },
        { label: "Submitted for Adjustment", value: ClaimStatus.Validating },
      ],
    },
    {
      key: "channels",
      label: "CHANNEL",
      clearable: true,
      multiselect: true,
      options:
        user?.channels.map(({ id, channelName }) => ({ label: channelName, value: id })) || [],
    },
  ];

  const downloadAsCsv = async (filename: string, filtered: boolean) => {
    const options = {
      fieldSeparator: ",",
      filename,
      quoteStrings: '"',
      decimalseparator: ".",
      showLabels: true,
      showTitle: false,
      useBom: false,
      useKeysAsHeaders: true,
    };
    const csvExporter = new ExportToCsv(options);
    let cleanedClaims: Partial<Claim & Item>[] = [];
    let claimsToExport: Partial<Claim & Item>[] = [];
    let rawClaims = filtered
      ? filteredClaims
      : claims.filter((claim) =>
          applyFilter(claim, {
            claimType: Object.values(ClaimType),
            status: [
              ClaimStatus.Completed,
              ClaimStatus.Validating,
              ClaimStatus.Submitted,
              ClaimStatus.Incomplete,
              ClaimStatus.Pending,
            ],
            channels: user.channels?.map(({ id }) => id),
          })
        );
    rawClaims.forEach((claim) => {
      // all 3 have id but we want to keep claim id (spread last)
      const claimFields = {
        ...stores.get(claim.storeId),
        ...channels.get(stores.get(claim.storeId)?.channelId),
        ...claim,
      };
      if (claim.items?.length > 0) {
        // split items of each claim into separate claimsToExport
        for (let i = 0; i < claim.items.length; i++) {
          // both have id but we want to keep claim id (spread last)
          claimsToExport.push({
            ...claim.items[i],
            ...claimFields,
          });
        }
      } else {
        claimsToExport.push(claimFields);
      }
    });

    cleanedClaims = cleanDataForCsv(claimsToExport);
    if (cleanedClaims.length < 1) {
      return;
    } else {
      try {
        csvExporter.generateCsv(cleanedClaims);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const cleanDataForCsv = (claimExport: Partial<Claim & Item>[]) => {
    let newClaims: Partial<Claim & Item>[] = [];

    claimExport.forEach((value, key) => {
      // Clean up date objects, rename headers, and order headers
      if (
        !value["goodsReceivedDate"] &&
        value["receivalDate"] &&
        value["type"] !== ClaimType.Recall
      ) {
        value["goodsReceivedDate"] = value["receivalDate"];
      }
      let obj = JSON.parse(JSON.stringify(value));
      obj["ClaimId"] = value["id"] ? value["id"] : value["claimId"];
      obj["DateSubmitted"] = value["createDate"]?.isValid
        ? value["createDate"]?.toFormat("yyyy-MM-dd")
        : DateTime.fromISO(value["createDate"]?.slice(0, -1))?.isValid
        ? DateTime.fromISO(value["createDate"]?.slice(0, -1))?.toFormat("yyyy-MM-dd")
        : "";
      obj["StatusDateModified"] = value["updateDate"]?.isValid
        ? value["updateDate"]?.toFormat("yyyy-MM-dd")
        : DateTime.fromISO(value["updateDate"]?.slice(0, -1))?.isValid
        ? DateTime.fromISO(value["updateDate"]?.slice(0, -1))?.toFormat("yyyy-MM-dd")
        : "";
      obj["ReceivalDate"] = value["goodsReceivedDate"]?.isValid
        ? value["goodsReceivedDate"]?.toFormat("yyyy-MM-dd")
        : DateTime.fromISO(value["goodsReceivedDate"]?.slice(0, -1))?.isValid
        ? DateTime.fromISO(value["goodsReceivedDate"]?.slice(0, -1))?.toFormat("yyyy-MM-dd")
        : "";
      obj["PurchaseNumber"] = value["purchaseNumber"] || "";
      obj["Type"] = value["type"];
      obj["Status"] = value["status"];
      obj["StoreName"] = value["storeOperatorName"];
      obj["StoreNumber"] = value["storeOperatorNumber"];
      obj["Channel"] = value["channelName"];
      obj["Item"] = [-1, "-1"].includes(value["itemNumber"]) ? "Custom" : value["itemNumber"] || "";
      obj["ItemDescription"] = value["productDescription"] || value["itemName"] || "";
      obj["Cases"] = value["cases"] ? obj["cases"] : "";
      obj["Units"] = value["units"] ? obj["units"] : obj["unitsClaimed"] || "";
      obj["Decision"] = value["isApproved"]
        ? "Approved"
        : value["isApproved"] !== false
        ? ""
        : "Not Approved";

      // Delete unneccessary columns
      Object.keys(obj).forEach((key) => {
        if (key.charAt(0) === key.charAt(0).toLowerCase()) {
          delete obj[key];
        }
      });

      newClaims.push(obj);
    });

    return newClaims;
  };

  const getData = async () => {
    let [userData, claimsData] = await Promise.all([
      UserService.fetchUser() as Promise<Reporter>,
      fetchClaims(),
    ]);

    let allChannels = new Map(
      (await getChannels())?.map((channel): [Channel["id"], Channel] => [channel.id, channel])
    );
    let allStores = new Map(
      (await getStores())?.map((store): [Store["id"], Store] => [store.id, store])
    );
    setUser(userData);
    filterGroups[2].options = userData?.channels?.map(({ id, channelName }) => ({
      label: channelName,
      value: id,
    }));
    setClaims(claimsData);
    setSearchClaims(claimsData);
    setChannels(allChannels);
    setStores(allStores);
    let userChannels = userData.channels;
    let newFilters = { ...filters, channels: userChannels?.map(({ id }) => id) };
    setFilters(newFilters);
    let filtered = claimsData.filter((claim) => applyFilter(claim, newFilters, allStores));
    setSearchClaims(filtered);
    setFilteredClaims(filtered);
    return [userData, claimsData];
  };

  const loadData = async () => {
    getData().then((results) => {
      setLoading(false);
    });
  };

  const applyFilter = (claim: Claim, selections?, storeList?) => {
    const filter = selections ? selections : filters;
    const storeArray = storeList ? storeList : stores;
    return (
      filter.status.includes(claim.status) &&
      filter.claimType.includes(claim.type) &&
      filter.channels.includes(storeArray.get(claim.storeId)?.channelId)
    );
  };

  const applyFilters = (selections?) => {
    let newClaims = claims.filter((claim) => applyFilter(claim, selections));
    setFilteredClaims(newClaims);
    setSearchClaims(newClaims);
  };

  const handleSearch = (value: string) => {
    value = value.toLowerCase();
    let result = filteredClaims.filter((claim) => {
      return (
        `${claim.type} ${claim.id}`.includes(value) ||
        claim.status.includes(value) ||
        claim.items
          .map(
            ({ itemNumber, itemName, productDescription }) =>
              `${itemNumber} ${productDescription ? productDescription : itemName} `
          )
          .join("")
          .toLowerCase()
          .includes(value)
      );
    });
    setSearchClaims(result);
  };

  useEffect(() => {
    if (UserService.getType() !== UserType.GrocerReporter) history.push("/");
    setLoading(true);
    loadData();
  }, []);

  return (
    <div className="reporter-home">
      <Header variant="home" reporter />
      <br />
      {currentClaim && (
        <Modal show={showInfo} onHide={() => setShowInfo(false)}>
          <ClaimInfoModal
            onClose={() => {
              setShowInfo(false);
            }}
            {...currentClaim}
          />
        </Modal>
      )}
      {showExports && (
        <RadioGroupModal
          show={showExports}
          title="Export as"
          groups={exportGroups}
          onCancel={() => {
            setShowExports(false);
          }}
          onSubmit={(selections) => {
            let results = { ...(selections as { fileType: "pdf" | "csv"; filtered: boolean }) };
            const channelNames = results.filtered
              ? filters.channels.map((id) => channels.get(id).channelName)
              : "exportAll";
            results.fileType === "pdf"
              ? exportPDF(
                  !results.filtered
                    ? { channels: "exportAll", claimIds: "exportAll" }
                    : {
                        claimIds: filteredClaims.map(({ id }) => id),
                        channels: filters.channels,
                      },
                  channelNames
                )
              : downloadAsCsv(
                  `${exportFileName()}_${
                    Array.isArray(channelNames) ? channelNames.join(",") : channelNames
                  }.csv`,
                  results.filtered
                );
            setShowExports(false);
          }}
        />
      )}
      {showFilters && (
        <RadioGroupModal
          allClearable
          show={showFilters}
          title="Filter by"
          groups={filterGroups}
          onCancel={() => {
            setShowFilters(false);
          }}
          onSubmit={(selections) => {
            setFilters(
              selections as { claimType: ClaimType[]; status: ClaimStatus[]; channels: number[] }
            );
            applyFilters(
              selections as { claimType: ClaimType[]; status: ClaimStatus[]; channels: number[] }
            );
            setShowFilters(false);
          }}
          defaultSelections={filters}
        />
      )}
      {loading ? (
        <>
          <div>Loading...</div>
          <div className="min-vh-100" />
        </>
      ) : (
        <>
          <div className="filters">
            <div className="reporter-search">
              <Search placeholder="Search..." onChange={handleSearch} clearable={false} />
            </div>
            <div className="button-pair">
              <Button
                onClick={() => {
                  setShowFilters(true);
                }}
                className="filter"
              >
                Filter
                <FilterIcon />
              </Button>
              <Button
                className="toggle"
                onClick={() => {
                  setCardView(!cardView);
                }}
              >
                {cardView ? <HamburgerIcon /> : <WindowIcon />}
              </Button>
            </div>
          </div>
          {cardView ? (
            <Row>
              {searchClaims
                .filter(({ status }) => status !== "draft")
                .map((claim) => (
                  <div className="col-xs-12 col-md-6">
                    <div className="reporter-card-container">
                      <ReporterClaimCard
                        onClick={() => {
                          setCurrentClaim(claim);
                          setShowInfo(true);
                        }}
                        {...claim}
                      />
                    </div>
                  </div>
                ))}
            </Row>
          ) : (
            <ReporterTable rawClaims={searchClaims} channels={channels} stores={stores} />
          )}
          <div className="footer-container">
            <StickyFooter escaped>
              <div className="reporter-footer">
                <Button
                  onClick={() => {
                    setShowExports(true);
                  }}
                >
                  Export Data
                </Button>
              </div>
            </StickyFooter>
          </div>
        </>
      )}
    </div>
  );
};

export default ReporterHome;

const ReporterTable: React.FC<{
  rawClaims: Claim[];
  channels: Map<Channel["id"], Channel>;
  stores: Map<Store["id"], Store>;
}> = ({ rawClaims = [], channels = new Map(), stores = new Map() }) => {
  const parseClaims = () =>
    rawClaims.flatMap((claim: Claim) => {
      const store = stores.get(claim.storeId);
      return claim.items.map((item: Item) => ({
        ...claim,
        ...item,
        ...store,
        ...channels.get(store.channelId),
        uniqueId: claim.id * 1000 + item.id,
        claimId: claim.id,
        itemCreateDate: item.createDate.toLocaleString(DateTime.DATE_MED) || "",
        itemUpdateDate: item.updateDate.toLocaleString(DateTime.DATE_MED) || "",
        statusText: mapClaimStatus(claim.status),
        itemReceivalDate: item.receivalDate?.toLocaleString(DateTime.DATE_MED) || "",
        decision: item.isApproved ? "Approved" : "Not Approved",
      }));
    });
  const [claims, setClaims] = useState(parseClaims());

  useEffect(() => {
    setClaims(parseClaims());
  }, [rawClaims]);

  const rowClasses = (row, rowIndex) => {
    return "reporter-table-row";
  };

  const columnClasses = (cell, row, rowIndex, colIndex) => {
    return "reporter-table-column";
  };

  const dateSort = (a: string, b: string, order: string) => {
    return (Date.parse(a) < Date.parse(b) ? 1 : -1) * (order === "asc" ? 1 : -1);
  };

  const COLUMNS = [
    {
      sort: true,
      headerClasses: "reporter-table-header-column",
      classes: columnClasses,
      dataField: "uniqueId",
      text: "",
      hidden: true,
    },
    {
      sort: true,
      headerClasses: "reporter-table-header-column",
      classes: columnClasses,
      dataField: "claimId",
      text: "Claim #",
    },
    {
      sort: true,
      sortFunc: dateSort,
      headerClasses: "reporter-table-header-column",
      classes: columnClasses,
      dataField: "itemCreateDate",
      text: "Date Created",
    },

    {
      sort: true,
      headerClasses: "reporter-table-header-column",
      classes: columnClasses,
      dataField: "statusText",
      text: "Status",
    },
    {
      sort: true,
      headerClasses: "reporter-table-header-column",
      classes: columnClasses,
      dataField: "type",
      text: "Type",
    },
    {
      sort: true,
      sortFunc: dateSort,
      headerClasses: "reporter-table-header-column",
      classes: columnClasses,
      dataField: "itemUpdateDate",
      text: "Date Updated",
    },

    {
      sort: true,
      sortFunc: dateSort,
      headerClasses: "reporter-table-header-column",
      classes: columnClasses,
      dataField: "itemReceivalDate",
      text: "Receival Date",
    },

    {
      sort: true,
      headerClasses: "reporter-table-header-column",
      classes: columnClasses,
      dataField: "purchaseNumber",
      text: "Purchase Order Number",
    },
    {
      sort: true,
      headerClasses: "reporter-table-header-column",
      classes: columnClasses,
      dataField: "storeOperatorNumber",
      text: "Store Number",
    },

    {
      sort: true,
      headerClasses: "reporter-table-header-column",
      classes: columnClasses,
      dataField: "storeOperatorName",
      text: "Store Name",
    },
    {
      sort: true,
      headerClasses: "reporter-table-header-column",
      classes: columnClasses,
      dataField: "channelName",
      text: "Channel",
    },

    {
      sort: true,
      headerClasses: "reporter-table-header-column",
      classes: columnClasses,
      dataField: "itemNumber",
      text: "Item",
    },
    {
      sort: true,
      headerClasses: "reporter-table-header-column",
      classes: columnClasses,
      dataField: "productDescription",
      text: "Item Description",
    },
    {
      sort: true,
      headerClasses: "reporter-table-header-column",
      classes: columnClasses,
      dataField: "cases",
      text: "Cases",
    },
    {
      sort: true,
      headerClasses: "reporter-table-header-column",
      classes: columnClasses,
      dataField: "units",
      text: "Units",
    },
    {
      sort: true,
      headerClasses: "reporter-table-header-column",
      classes: columnClasses,
      dataField: "decision",
      text: "Decision",
    },
  ];

  return (
    <div className="reporter-table-container">
      <BootstrapTable
        bootstrap4
        hover
        keyField={"uniqueId"}
        classes="reporter-table"
        wrapperClasses="reporter-table-wrapper"
        headerClasses="reporter-table-header"
        rowClasses={rowClasses}
        data={claims}
        columns={COLUMNS}
        defaultSorted={[
          {
            dataField: "uniqueId",
            order: "asc",
          },
        ]}
      />
    </div>
  );
};
