import { UppyFile } from "@uppy/core";
import { DateTime } from "luxon";
import Dropdown from "components/atoms/Dropdown";
import ImageUploader from "components/atoms/ImageUploader";
import TextInput from "components/atoms/TextInput";
import { useEffect, useState } from "react";
import { fetchClaim, patchClaim } from "services/ClaimService";
import { deletePhoto, savePhoto } from "services/PhotoService";
import { Adjustment, Recall, Refund } from "types";
import { ClaimType } from "types/claim";
import { Photo } from "types/photo";
import { ClaimComponentProps } from ".";

export type BasicInfoProps = {
  data: Partial<Adjustment & Refund & Recall & { created: any }>; //TODO remove created now there's typechecks
  type: ClaimType;
  id: number;
  setDisposition?: React.Dispatch<"return" | "destroy">;
} & ClaimComponentProps;

const BasicInfo: React.FC<BasicInfoProps> = ({
  data,
  type,
  id,
  setDisposition,
  setCanProgress,
  setOnNext,
  viewOnly,
}) => {
  const [claim, setClaim] = useState(data);
  const [photos, setPhotos] = useState([]);
  const [toUpload, setToUpload] = useState([]);
  const [toDelete, setToDelete] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [showEmailStatus, setShowEmailStatus] = useState(false);

  const getClaim = async () => {
    let claimData = await fetchClaim(type, id);
    let claimPhotos = claimData.photos;
    setClaim(claimData);
    setPhotos(
      claimPhotos
        ? claimPhotos.map((p: Photo) =>
            p.mimetype === "application/pdf" ? `pdf-${p.name} |<break>| ${p.url}` : p.url
          )
        : []
    );
    if (claimData) {
      setOnNext(() => async () => {});
      type === ClaimType.Recall && setCanProgress(checkCanProgress(checkEmailFormat(claimData.storeEmail)));
      setLoading(false);
    }
  };

  const updateOnNext = (
    newClaim: Partial<Refund & Adjustment & Recall>,
    newPhotos?: UppyFile[],
    toRemove?: Pick<Photo, "id">[]
  ) => {
    const claimID = id;
    let updatedPhotos = newPhotos ? newPhotos : toUpload;
    let removedPhotos = toRemove ? toRemove : toDelete;
    setOnNext(() => async () => {
      let { id, alert, photos, storeId, type, createDate, updateDate, ...rest } = newClaim;
      return await Promise.all([
        await Promise.all(
          updatedPhotos.map(async (photo) => {
            return await savePhoto(type, claimID, photo);
          })
        ),
        await patchClaim(claimID, type, rest),
        await Promise.all([
          removedPhotos.map(async (photoId) => {
            return await deletePhoto(type, claimID, photoId);
          }),
        ]),
      ]);
    });
  };

  useEffect(() => {
    setCanProgress(false);
    setLoading(true);
    getClaim();
  }, []);

  useEffect(() => {
    setCanProgress(checkCanProgress());
  }, [claim, photos, toUpload, toDelete]);

  const checkCanProgress = (validEmail?: boolean) => {
    const checkEmail = validEmail !== undefined ? validEmail : isValidEmail;
    return (
      viewOnly ||
      (type === ClaimType.Recall &&
        claim.disposition?.length > 0 &&
        claim.storeOwnerName?.length > 0 &&
        claim.storeEmail?.length > 0 &&
        checkEmail) ||
      (claim.completedBy?.length > 0 &&
        photos?.length > 0 &&
        (type === ClaimType.Refund ||
          (type === ClaimType.Adjustment &&
            claim.carrierName !== null &&
            claim.carrierName !== "" &&
            claim.bolNumber !== null &&
            claim.bolNumber !== "")))
    );
  };

  const checkEmailFormat = (value: string) => {
    const validEmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = validEmailRegex.test(value);
    setIsValidEmail(isValid);
    return isValid;
  };

  const modifyClaim = (field: string, value: any) => {
    if (field === "disposition") {
      setDisposition(value);
    }
    let newClaim = JSON.parse(JSON.stringify(claim));
    newClaim[field] = value;
    setClaim(newClaim);
    updateOnNext(newClaim);
  };

  const handleUpload = (newPhotos: UppyFile[]) => {
    const newPhotoURLs = newPhotos.map((photo) => {
      if (photo.type) {
        return photo.type === "application/pdf"
          ? `pdf-${photo.name} |<break>| ${URL.createObjectURL(photo.data)}`
          : photo.preview;
      }
      return undefined;
    });
    setPhotos([...photos, ...newPhotoURLs]);
    setToUpload([...toUpload, ...newPhotos]);
    updateOnNext(claim, [...toUpload, ...newPhotos]);
  };

  const handleDelete = (deleted) => {
    setPhotos(photos.filter((photo) => photo !== deleted));
    const newUploadList = toUpload.filter((photo) => {
      if (deleted.substring(0, 3) === "pdf") {
        return !deleted.includes(`${photo.name}`);
      } else {
        return photo.preview !== deleted;
      }
    });
    setToUpload(newUploadList);
    let deletedPhoto = claim.photos?.filter((p) => deleted.includes(p.url));
    if (deletedPhoto?.length > 0) {
      setToDelete([...toDelete, ...deletedPhoto.map((p) => p.id)]);
      updateOnNext(claim, newUploadList, [...toDelete, ...deletedPhoto.map((p) => p.id)]);
    } else {
      updateOnNext(claim, newUploadList);
    }
  };

  return loading ? (
    <></>
  ) : (
    <>
      {claim !== undefined && (
        <div>
          {type !== ClaimType.Adjustment && <strong>Basic Information</strong>}
          <div className="info-fields">
            {/* {JSON.stringify(claim)} */}
            {type === ClaimType.Recall ? (
              <>
                <div className="info-field">
                  <TextInput
                    label="Date Prepared"
                    defaultValue={
                      claim.createDate
                        ? DateTime.fromISO(claim.createDate).toLocaleString(DateTime.DATETIME_FULL)
                        : DateTime.fromISO(claim.created).toLocaleString(DateTime.DATETIME_FULL)
                    }
                    onChange={() => {}}
                    disabled
                  />
                </div>
                <div className="info-field">
                  <TextInput
                    defaultValue={claim.storeOwnerName}
                    label="Name of Store Owner/Manager"
                    placeholder="e.g. Store manager's name"
                    onChange={(e) => {
                      modifyClaim("storeOwnerName", e.target.value);
                    }}
                    disabled={viewOnly}
                    asterisk
                  />
                </div>
                <div className="info-field">
                  <TextInput
                    defaultValue={claim.storeEmail}
                    label="Work Email"
                    placeholder="e.g. Company/store's email"
                    onChange={(e) => {
                      modifyClaim("storeEmail", e.target.value);
                      checkEmailFormat(e.target.value);
                    }}
                    onFocus={() => setShowEmailStatus(false)}
                    onBlur={() => setShowEmailStatus(true)}
                    disabled={viewOnly}
                    asterisk
                  />
                  <p className="mt-1 incomplete input-warning">
                    {!isValidEmail && showEmailStatus ? "Please enter a valid email address" : ""}
                  </p>
                </div>
                {/* <div className="info-field">
                  {viewOnly ? (
                    <TextInput
                      label="Title"
                      defaultValue={"claim.title"}
                      onChange={() => {}}
                      disabled
                    />
                  ) : (
                    <Dropdown
                      label="Title"
                      placeholder="Select title..."
                      options={[
                        { label: "Owner", value: "owner" },
                        { label: "Manager", value: "manager" },
                      ]}
                      onSelect={() => {}}
                    />
                  )}
                </div> */}
                <div className="info-field">
                  {viewOnly ? (
                    <TextInput
                      label="Request to:"
                      value={claim.disposition}
                      onChange={() => {}}
                      disabled={viewOnly}
                      asterisk
                    />
                  ) : claim.alert.disposition !== "both" ? (
                    <Dropdown
                      label="Request to:"
                      placeholder="Select an option..."
                      value={claim.disposition}
                      options={[
                        {
                          label:
                            claim.alert.disposition === "destroy"
                              ? "Destroy Onsite"
                              : "Return to LCBO",
                          value: claim.alert.disposition,
                        },
                      ]}
                      onSelect={(value) => {
                        modifyClaim("disposition", value);
                      }}
                      asterisk
                    />
                  ) : (
                    <Dropdown
                      label="Request to:"
                      placeholder="Select an option..."
                      options={[
                        { label: "Return to LCBO", value: "return" },
                        { label: "Destroy Onsite", value: "destroy" },
                      ]}
                      onSelect={(value) => {
                        modifyClaim("disposition", value);
                      }}
                      value={claim.disposition}
                      asterisk
                    />
                  )}
                </div>
                <div className="info-field">
                  <div className="lot">
                    <p>LOT CODE(S): {claim.alert?.lotCodes?.join(", ")}</p>
                  </div>
                </div>
              </>
            ) : (
              <>
                {type === ClaimType.Adjustment && (
                  <>
                    <br />
                    <strong>Carrier Details</strong>
                    <div className="info-field">
                      <TextInput
                        label="Carrier Name"
                        defaultValue={claim.carrierName || ""}
                        onChange={(e) => {
                          modifyClaim("carrierName", e.target.value);
                        }}
                        disabled={viewOnly}
                        asterisk
                      />
                    </div>
                    <div className="info-field">
                      <TextInput
                        label="Order Invoice Number"
                        defaultValue={claim.bolNumber || ""}
                        onChange={(e) => {
                          modifyClaim("bolNumber", e.target.value);
                        }}
                        disabled={viewOnly}
                        asterisk
                      />
                    </div>
                  </>
                )}
              </>
            )}
            {type !== ClaimType.Recall && (
              <>
                <div className="info-field">
                  <div className="image-copy">
                    <div>
                      <p>
                        {type === ClaimType.Refund && <strong>CUSTOMER COMPLAINT FORM PHOTOS</strong>}
                        {type === ClaimType.Adjustment && <strong>ORDER INVOICE PHOTO</strong>}
                        <div className="asterisk">*</div>
                      </p>
                    </div>

                    <div>
                      <p>
                        <ul>
                          {type === ClaimType.Refund && (
                            <>
                              <li>Upload a copy of the Customer Complaint Form, this form must be signed by the customer.</li>
                              <li>Submit images of product.</li>
                            </>
                          )}
                          {type === ClaimType.Adjustment && <li>A signed copy of the Product Summary Page of the Invoice.</li>}
                        </ul>
                      </p>
                    </div>
                  </div>
                </div>
                <ImageUploader
                  id={claim.type + " " + claim.id.toString()}
                  disableChanges={viewOnly}
                  images={photos}
                  onUpload={handleUpload}
                  onDelete={handleDelete}
                />
                <div className="info-field">
                  <TextInput
                    label="Completed by"
                    defaultValue={claim.completedBy}
                    onChange={(e) => {
                      modifyClaim("completedBy", e.target.value);
                    }}
                    placeholder="Your full name"
                    disabled={viewOnly}
                    asterisk
                  />
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default BasicInfo;
