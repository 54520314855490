import React, { useState, useEffect } from "react";
import Search from "components/atoms/Search";
import ClaimCard from "components/atoms/ClaimCard";
import Expandable from "./Expandable";
import { Row } from "react-bootstrap";
import { ClaimStatus, Claim } from "types";
import { UserService } from "services/UserService";

export type ClaimBrowserProps = {
  claims: Claim[];
  onSelect: (claim: Claim) => void;
  loading?: boolean;
};

const ClaimBrowser: React.FC<ClaimBrowserProps> = ({ claims, onSelect, loading = false }) => {
  const CLAIM_STATUS_MAP = {
    action: "Action Required",
    draft: "Drafts",
    submitted: "Claims in Progress",
    completedWithDeclined: "Completed with Declined Items",
    completedWithApproval: "Completed with Full Approval",
  };

  const [sortedClaims, setSortedClaims] = useState({
    action: [],
    draft: [],
    submitted: [],
    completedWithDeclined: [],
    completedWithApproval: [],
  });
  const [search, setSearch] = useState("");
  const [searchClaims, setSearchClaims] = useState([]);

  useEffect(() => {
    let currentSortedClaims = {
      action: [],
      draft: [],
      submitted: [],
      completedWithDeclined: [],
      completedWithApproval: [],
    };
    claims.forEach((claim) => {
      let lastComment = undefined;
      claim.comments
        .filter((comment) => comment !== undefined && comment !== null)
        .forEach((comment) => {
          if (
            lastComment === undefined ||
            comment.updateDate.toMillis() > lastComment.updateDate.toMillis()
          ) {
            lastComment = comment;
          }
        });
      let notification, completeWithoutDeclined: boolean;
      notification =
        lastComment !== undefined &&
        lastComment.message !== "" &&
        UserService.getId() !== lastComment.user.id;
      completeWithoutDeclined =
        claim.items.filter((item) => item.isApproved).length === claim.items.length;
      if (notification) {
        completeWithoutDeclined
          ? currentSortedClaims.action.push({ ...claim, notification: true })
          : currentSortedClaims.action.push({
              ...claim,
              declinedItems: true,
              notification: true,
            });
      } else {
        switch (claim.status) {
          case ClaimStatus.Draft:
            currentSortedClaims.draft.push({ ...claim, notification: false });
            break;
          case ClaimStatus.Incomplete:
            currentSortedClaims.action.push({ ...claim, notification });
            break;
          case ClaimStatus.Submitted:
            currentSortedClaims.submitted.push({
              ...claim,
              notification: false,
              status: ClaimStatus.Submitted,
            });
            break;
          case ClaimStatus.Completed:
            completeWithoutDeclined
              ? currentSortedClaims.completedWithApproval.push({ ...claim, notification: false })
              : currentSortedClaims.completedWithDeclined.push({
                  ...claim,
                  declinedItems: true,
                  notification: false,
                });
            break;
        }
      }
    });
    setSortedClaims(currentSortedClaims);
  }, [claims]);

  const handleSearch = (value: string) => {
    value = value.toLowerCase();
    let result = Object.keys(sortedClaims)
      .flatMap((key) => sortedClaims[key])
      .filter((claim) => {
        if (
          claim.id.toString().includes(value) ||
          claim.status.includes(value) ||
          claim.type.includes(value)
        ) {
          return true;
        }
        return false;
      });
    setSearchClaims(result);
    setSearch(value);
  };

  return (
    <div className="claim-browser">
      <div className="searcher">
        <Search onChange={handleSearch} />
      </div>
      {search === "" ? (
        Object.keys(CLAIM_STATUS_MAP).map((status) => (
          <Expandable defaultValue={status === "action"} label={CLAIM_STATUS_MAP[status]}>
            <Row>
              {sortedClaims[status].map((claim) => (
                <div className="col-xs-12 col-sm-6 claim-col">
                  <ClaimCard {...claim} onClick={() => onSelect(claim)} />
                </div>
              ))}
              {!sortedClaims[status].length && (
                <div className="none-found">
                  {loading ? (
                    <i>Loading...</i>
                  ) : (
                    <i>No {CLAIM_STATUS_MAP[status].toLowerCase()} found.</i>
                  )}
                </div>
              )}
            </Row>
          </Expandable>
        ))
      ) : (
        <Row>
          {searchClaims.map((claim) => (
            <div className="col-xs-12 col-sm-6 claim-col">
              <ClaimCard {...claim} onClick={() => onSelect(claim)} />
            </div>
          ))}
          {!searchClaims.length && (
            <div className="bad-search">No claims matching your search were found.</div>
          )}
        </Row>
      )}
    </div>
  );
};

export default ClaimBrowser; 
