import { string, number, date, boolean, object, array } from "yup";

const itemSchema = object({
  id: number().defined(),
  itemNumber: number().defined(),
  createDate: date().defined(),
  updateDate: date().defined(),
  isApproved: boolean().defined(),
  cases: number(),
  units: number(),
  photos: array().of(object()),
  itemInfo: object(),
});

const refundItemSchema = itemSchema.concat(
  object({
    reason: string().defined(),
    purchaseNumber: string().nullable(),
    receivalDate: date().nullable(),
    productDescription: string(),
  })
);

const adjustmentItemSchema = itemSchema.concat(
  object({
    purchaseNumber: string().nullable(),
    productDescription: string(),
    claimCategory: string(),
    itemReceived: boolean().nullable(),
    skidsTied: boolean().nullable(),
    casePositionOnSkid: string().nullable(),
    caseSealed: boolean().nullable(),
    breakageType: string(),
    breakageLocation: string(),
    driverAgree: boolean().nullable(),
  })
);

const recallItemSchema = itemSchema.concat(
  object({
    claimId: number().defined(),
  })
);

export { itemSchema, refundItemSchema, adjustmentItemSchema, recallItemSchema };
