import { Button, Header } from "components";
import React, { useState, useEffect } from "react";
import { DateTime } from "luxon";
import { Claim, ClaimStatus, ClaimType } from "types";
import Comments from "components/organisms/Comments";
import Modal from "components/organisms/Modal";
import { useHistory } from "react-router-dom";
import { fetchClaim, patchClaim } from "services/ClaimService";
import { getProductInfo } from "services/ItemService";

const ClaimPreview: React.FC<{ match }> = ({ match }) => {
  const [claim, setClaim] = useState<Claim>(undefined);
  const [items, setItems] = useState(undefined);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const history = useHistory();

  const getClaim = async () => {
    // 404 not found stops execution, resulting in permanent "loading" and
    // error not being properly set. backend issue?
    const claimData = await fetchClaim(match?.params?.type, match?.params?.id);
    let itemsData = [];

    if (claimData) {
      claimData.items.forEach(
        ({ itemNumber, itemName, productDescription, purchaseNumber, isApproved }) => {
          const itemNameDesc = claimData.type === ClaimType.Recall ? itemName : productDescription;
          itemsData.push({ itemNumber, itemName: itemNameDesc, purchaseNumber, isApproved });
        }
      );
      setClaim(claimData);
      setItems(itemsData);
    } else setError(true);
  };

  useEffect(() => {
    setLoading(true);
    getClaim().then(() => setLoading(false));
  }, []);

  const handleOpenClaim = () => {
    patchClaim(claim.id, claim.type, { status: ClaimStatus.Incomplete }).then(() => {
      history.push(`/claim/${claim.type}/${claim.id}`);
    });
  };

  const handleViewClaim = () => {
    history.push(`/claim/${claim.type}/${claim.id}`);
  };

  const HEADERS = {};
  HEADERS[ClaimType.Refund] = "Refund Claim";
  HEADERS[ClaimType.Adjustment] = "Adjustment Claim";
  HEADERS[ClaimType.Recall] = "Recall Claim";

  const STATUS_MAP = {};
  STATUS_MAP[ClaimStatus.Incomplete] = "Incomplete";
  STATUS_MAP[ClaimStatus.Completed] = "Completed";
  STATUS_MAP[ClaimStatus.Submitted] = "Submitted";
  STATUS_MAP[ClaimStatus.Draft] = "Draft";

  const isCompleteWithDeclined =
    claim?.status === "completed" &&
    claim.items?.some((item) => !item.isApproved && !item.approved);

  let statusText = "";
  let statusColour = "";
  if (isCompleteWithDeclined) statusText = "Completed with declined items";
  else statusText = STATUS_MAP[claim?.status] || claim?.status;

  if (claim?.status === ClaimStatus.Completed && !isCompleteWithDeclined) statusColour = "complete";
  else if (claim?.status === ClaimStatus.Submitted) statusColour = "submitted";
  else if (claim?.status === ClaimStatus.Incomplete) statusColour = "incomplete";
  else statusColour = "in-progress";

  return (
    <div className="claim-page">
      <Header
        variant="status"
        onBack={() => {
          history.push("/");
        }}
      />
      {loading ? (
        "Loading..."
      ) : (
        <>
          {error ? (
            <>
              <strong>Claim not found</strong>
              <br />
              <div>Oops! The claim you are looking for does not exist or has been deleted.</div>
            </>
          ) : (
            <>
              <div className="recall-header top-padding">
                <div className="header">{`${HEADERS[claim?.type]} #${claim?.id}`}</div>
                {claim?.alert?.alertNumber && <div className="sub-header">{`Alert #${claim.alert.alertNumber}`} </div>}
                <div className="sub-header">
                  {items?.map(({ itemNumber, itemName, productDescription }) =>
                    itemNumber > 0 ? (
                      <div key={`sub-${itemNumber}`}>{`${itemNumber} - ${itemName}`}</div>
                    ) : (
                      <div key={`sub-${productDescription}`}>Custom Item</div>
                    )
                  )}
                </div>
                <div className="button-container">
                  <Button styling="blue-fill-btn" onClick={handleViewClaim}>
                    {claim?.status === ClaimStatus.Draft || claim?.status === ClaimStatus.Incomplete
                      ? "Edit Claim"
                      : "View Claim"}
                  </Button>
                  {isCompleteWithDeclined && (
                    <Button styling="outline-btn" onClick={() => setShowModal(true)}>
                      Re-open Claim
                    </Button>
                  )}
                </div>
                <hr />
              </div>

              <Modal
                handleClose={() => {
                  setShowModal(false);
                }}
                show={showModal}
              >
                <div className="reopen-modal">
                  <h4 className="modal-header">Wait!</h4>
                  <div className="modal-body">
                    <div>
                      Are you sure you want to re-open{" "}
                      <strong>{`${HEADERS[claim?.type]} #${claim?.id}`}</strong>
                    </div>
                    <div>Re-opening the claim will move this claim to:</div>
                    <div>
                      Category: <strong>Action Required</strong>
                    </div>
                    <div>
                      Status: <strong className="incomplete">Incomplete</strong>
                    </div>
                    <div className="button-container ">
                      <Button styling="blue-fill-btn" onClick={handleOpenClaim}>
                        Re-open Claim
                      </Button>
                      <Button styling="outline-btn" onClick={() => setShowModal(false)}>
                        Cancel
                      </Button>
                    </div>
                  </div>
                </div>
              </Modal>

              <div className="info-fields">
                <div className="info-field status-style">
                  <strong>General Status</strong>
                  <div>
                    Status: <div className={statusColour}>{statusText}</div>
                  </div>
                  <div>
                    {claim?.status === ClaimStatus.Draft ? "Date Created" : "Date Submitted"}:{" "}
                    {claim?.createDate &&
                      DateTime.fromISO(claim?.createDate).toLocaleString(DateTime.DATE_FULL)}
                  </div>
                  <div>
                    Last Updated:{" "}
                    {claim?.updateDate &&
                      DateTime.fromISO(claim?.updateDate).toLocaleString(DateTime.DATE_FULL)}
                  </div>
                </div>
                <div className="info-field status-style">
                  <strong>Item Decision</strong>
                  {!items?.length ? (
                    <>
                      <br />
                      <div className="missing">Missing Items</div>
                    </>
                  ) : (
                    <>
                      {items?.map(
                        ({
                          itemName,
                          itemNumber,
                          purchaseNumber,
                          isApproved,
                          productDescription,
                        }) => (
                          <div key={`item-${itemNumber > 0 ? itemNumber : productDescription}`}>
                            {itemNumber > 0 ? `${itemNumber} - ${itemName}` : "Custom Item"}
                            {purchaseNumber && ` (Purchase #${purchaseNumber})`}:{" "}
                            {isApproved ? (
                              <strong className="complete">Approved</strong>
                            ) : (
                              <strong className="incomplete">Not Approved</strong>
                            )}
                          </div>
                        )
                      )}
                    </>
                  )}
                </div>
                <div className="info-field status-style">
                  <strong>Claim History</strong>
                  <Comments {...claim} onSubmit={getClaim} />
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ClaimPreview;
