import { DateTime } from "luxon";
import { Alert } from "./alert";
import { Item } from "./item";
import { Photo } from "./photo";
import { Comment } from "./comment";
import { User } from "./user";

enum ClaimType {
  Refund = "refund",
  Adjustment = "adjustment",
  Recall = "recall",
}

enum ClaimStatus {
  Pending = "pending",
  Incomplete = "incomplete",
  Completed = "completed",
  Submitted = "submitted",
  Draft = "draft",
  Validating = "validating",
  Approved = "approved",
}

interface Claim {
  id: number;
  userId: number;
  storeId: number;
  type: ClaimType;
  status: ClaimStatus;
  createDate: DateTime;
  updateDate: DateTime;
  photos?: Photo[];
  completedBy: string;
  comments: Comment[];
  items: Item[];
  user?: User;
  goodsReceivedDate?: DateTime;
}
interface Refund extends Claim {
  notification: boolean | null;
}

interface Adjustment extends Claim {
  notification: boolean;
  goodsReceivedDate: string;
  bolNumber: string;
  carrierName: string;
  frozenLoad: boolean;
  sealIntact: boolean;
  sealNumber: string;
  isClaimWithDeniedItem: boolean;
}

enum Disposition {
  destroy = "destroy",
  return = "return",
}

// Note: Recalls have a different API return type that Refund and Adjustments. Type casts are done during the fetch
// to add attributes like "createDate" whereas the API returns "created" instead. (id & userId are strings)
interface Recall extends Claim {
  alertId: string;
  disposition?: Disposition;
  lotCodes?: string[];
  alert?: Alert;
  completedByTitle: string;
  storeOwnerName: string;
  storePhoneNumber: string;
  storeEmail: string;
}

type OverallClaim = Claim & Partial<Refund & Adjustment & Recall>;

function isRefund(refund: Claim): refund is Refund {
  return refund.type === ClaimType.Refund;
}

function isAdjustment(adjustment: Claim): adjustment is Adjustment {
  return adjustment.type === ClaimType.Adjustment;
}

function isRecall(recall: Claim): recall is Recall {
  return recall.type === ClaimType.Recall;
}

export function mapClaimStatus(status: Claim["status"]): string {
  let statusText: string;
  switch (status) {
    case "validating":
    case ClaimStatus.Validating:
      statusText = "submitted for adjustment";
      break;
    case "submitted":
    case ClaimStatus.Submitted:
      statusText = "grocer submitted";
      break;
    case "approved":
    case ClaimStatus.Approved:
      statusText = "approved by QA";
      break;
    default:
      statusText = status;
      break;
  }
  return statusText;
}

export type { Refund, Adjustment, Recall, OverallClaim as Claim };
export { ClaimType, ClaimStatus, isRefund, isAdjustment, isRecall };
